/* Parallax base styles
  --------------------------------------------- */

  .parallax {
    height: 1000px; /* fallback for older browsers */
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-perspective: 300px;
    perspective: 300px;
  }

  .parallax__group {
    position: relative;
    height: 500px; /* fallback for older browsers */
    min-height: 60vh;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  .parallax__layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .parallax__layer--fore {
    -webkit-transform: translateZ(70px) scale(.6);
    transform: translateZ(70px) scale(.6);
    z-index: 1;
  }

  .parallax__layer--base {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    z-index: 4;
  }

  .parallax__layer--back {
    -webkit-transform: translateZ(-300px) scale(2);
    transform: translateZ(-300px) scale(2);
    z-index: 3;
  }

  .parallax__layer--deep {
    -webkit-transform: translateZ(-600px) scale(3);
    transform: translateZ(-600px) scale(3);
    z-index: 2;
  }


  /* Debugger styles - used to show the effect
  --------------------------------------------- */

  .debug {
    position: fixed;
    top: 0;
    left: .5em;
    z-index: 999;
    background: rgba(0,0,0,.85);
    color: #fff;
    padding: .5em;
    border-radius: 0 0 5px 5px;
  }
  .debug-on .parallax__group {
    -webkit-transform: translate3d(300px, 0, -600px) rotateY(-10deg);
    transform: translate3d(300px, 0, -600px) rotateY(-10deg);
 }
  .debug-on .parallax__layer {
    box-shadow: 0 0 0 2px #000;
    opacity: 0.9;
  }
  .parallax__group {
    -webkit-transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
  }


  /* demo styles
  --------------------------------------------- */

   /* centre the content in the parallax layers */
  .title {
    text-align: left;
    position: absolute;
    left: 50%;
    top: 80%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
	.title-full {
		width:100%;
    text-align: left;
    position: absolute;
    left: 100%;
    top: 100%;
    -webkit-transform: translate(-90%, -120%);
    transform: translate(-90%, -120%);
    height: 70vh;
  }



  /* style the groups
  --------------------------------------------- */

  #group1 {
    z-index: 5; /* slide over group 2 */
  }
  #group1 .parallax__layer--base {
    background-color: rgb(102,204,102);
  }

  #group2 {
    z-index: 3; /* slide under groups 1 and 3 */
  }
  #group2 .parallax__layer--back {
    background-color: rgb(123,210,102);
  }

  #group3 {
    z-index: 4; /* slide over group 2 and 4 */
  }
  #group3 .parallax__layer--base {
    background-color: #232323;
    height:130vh;
  }


  #group4 {
    z-index: 5; /* slide under group 3 and 5 */
		margin-top:0vh;
    height: 85vh;
  }
  #group4 .title{
    transform: translate(-50%, -35%);

  }
	#group4 .parallax__layer--fore {
		background:#000;
    min-height: 120vh;
    height: auto !important;
  }
  #group4 .parallax__layer--back {
    /*background-color: rgb(184,223,101);*/
    background-color:#63C;
  }

  #group5 {
    z-index: 6; /* slide over group 4 and 6 */
  }
  #group5 .parallax__layer--fore {
    /*background-color: rgb(214,229,100);*/
    height:100vh;
  }
  @media (orientation: landscape) {
    #group5 .parallax__layer--fore {
      margin-top:0vh;
    }
}

@media (orientation: portrait) {
  #group5 .parallax__layer--fore {
    height:100vh;
    margin-top:-17vh;
  }
}

  #group5 .parallax__layer--base {
    /*background-color: rgb(214,229,100);*/
  }

  #group6 {
    z-index: 2; /* slide under group 5 and 7 */
  }
  #group6 .parallax__layer--back {
    background-color: rgb(245,235,100);
  }

  #group7 {
    z-index: 3; /* slide over group 7 */
  }
  #group7 .parallax__layer--base {
    background-color: rgb(17, 24, 39);
  }


  /* misc
  --------------------------------------------- */
  .demo__info {
    position: absolute;
    z-index:100;
    bottom: 1vh;
    top: auto;
    font-size:80%;
    text-align:center;
    width: 100%;
  }

	.picture{
		transition: transform 1s ease;
		transform: scale(1.1);
	}
	.picture.is-active{
		transform: scale(1);
	}

  #pictureGrow{
    transition: transform 2s ease;
    transition-delay:1s;
  }
  img {
    -webkit-backface-visibility: hidden;
    -ms-transform: translateZ(0); /* IE 9 */
    -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
    transform: translateZ(0);
  }
