@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  /* ... */
}

:root {
  font-family: InterVariable, sans-serif;
  font-feature-settings: 'cv08' off, 'zero' on, 'dlig' on, 'calt' on, 'ss03' on, 'ss04' on, 'cv05' on, 'cv06' on,'cv08' off,  'ss03' on; /* fix for Chrome */
  transform-origin: top;
}

a{cursor:pointer!important;}

body{
  -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
    margin: 0;
    overscroll-behavior: none;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    font-family: Inter, sans-serif;
    background-color: rgb(17 24 39);/*bg-gray-900*/
    color:#d1d3d9;
    font-weight:300;
    letter-spacing:1.6px;
    top:0px !important;
}

.skiptranslate,.goog-gt-tt {
    display:none !important;
}


img {
  max-width:100%;
}

nav {z-index: 10;
    background: transparent;
    backdrop-filter: blur(30px);
  }

  #main{
    /*max-width: 1300px;*/
      margin: 0 auto;
    }
  a.glow{
    transition:box-shadow 1s ease;
  }

  a.glow:hover, .glowIt{
    box-shadow:
    inset 0 0 25px #fff,      /* inner white */
    inset 3px 0 20px #f0f,   /* inner left magenta short */
    inset -3px 0 20px #0ff,  /* inner right cyan short */
    inset 3px 0 80px #f0f,  /* inner left magenta broad */
    inset -3px 0 80px #0ff, /* inner right cyan broad */
    0 0 25px #fff,            /* outer white */
    -3px 0 20px #f0f,        /* outer left magenta */
    3px 0 20px #0ff;         /* outer right cyan */
  }
  h1,.glowTitle{
    text-shadow: 0 0 10px #666,-3px 0 10px #f0f, 3px 0 10px #0ff !important;
  }

  .glow-wipe {
  text-align: center;

  background: linear-gradient(to right, #FFF -20%, #f0f 40%, #0ff 60%);
  background-size: 200% auto !important;

  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 1s linear infinite;
  @keyframes shine {

    to {
      background-position: 200% center;
    }
  }
}

  #projects{
    height:auto;
    /*overflow: hidden;
    overflow-y: scroll;*/
    max-width: 1300px;
    margin: 0 auto;
  }

  .bg-gradient-black{
    width:100%;height:100%;
    display:block;
    background-image: linear-gradient(-180deg, rgba(0,0,0,0) 60%, rgb(17 24 39));
  }
  .magenta{
    color:#f22a49d9;
    background:linear-gradient(93deg,#ee2585 0%, #f22a49d9 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
  .magenta-dark{
    color:#d71d3ad9
  }
  .magenta-light{
    color:#f4cde0;
  }
  .bg-magenta{
    background-color: #464a53;
    background: linear-gradient(0deg,#464a53 10%, #30343d 80%);
  }
  .bg-magenta-dark{
      background-color:#555a65;
  }

.fadeIn {
  animation: 1.5s fadeIn forwards;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
}



@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(0, 0px) scale(1, 1);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px) scale(1, 1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px) scale(1, 1);
  }
  to {
    transform: translate(0, -0px)  scale(1, 1);
    opacity: 0;
  }
}

/* Work */
.zoomIn {
  animation: 1.2s zoomIn forwards;
  transform-origin: center;
}

@keyframes zoomIn {
  from {
    transform: scale(1.2, 1.2);
  }
  to {
    transform: scale(1.3, 1.3);
  }
}


.zoomOut {
  animation: 1.2s zoomOut forwards;
  transform-origin: center;
}

@keyframes zoomOut {
  from {
    transform: scale(1.3, 1.3);
  }
  to {
    transform: scale(1.2, 1.2);
  }
}

img.project_featured {
  height: 160vh;
  width: 100%;
  object-fit: cover;
  object-position: center top;
  min-width:100%;
}

/* animated grid */
.galleryScale {
  margin: 0;
  background-color: white;
}


.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  transition: transform 2s ease-in-out;
}
.column:nth-child(1){
  transform-origin: left;
}
.column:nth-child(2){
  position:relative;
  transform-origin: center top;
  overflow-y: scroll;
}
.column:nth-child(3){
  transform-origin: right;
}

.orange-column {
  background-color: orange;
  height: 100px;
}

.blue-column {
  background-color: blue;
  height: 100px;
}

.green-column {
  background-color: green;
  height: 100px;
}
/* discover */
.discover{
  transition: opacity 1s ease;
  background:#000;
  height:100vh;
}
.discover-false{
  opacity:0;
}
.discover-true{
  opacity:1;
}
.bg-pink {
  background-color: #ee2585;
}
.logoIcon{
  width:80px;
  padding:10px 0px;
}
.logoIconInline{
    width:80px;
    margin:0 auto;
    margin-top:60px;
}

.nav,.navBtn {transition: transform 1s ease,height 1s ease;}
.nav-false{transform: translate(0,-150vh); overflow:hidden;}
.nav-true{transform: translate(0,0); height:100vh; overflow:hidden;}
.navBtn.open-false{
transform: rotate(45deg);
}
.navBtn.open-true{
transform: rotate(180deg);
}

.closeProject{
    position: fixed;
    left: -90px;
    width: 80px;
    top: 30%;
  }
  .closeProject1{
      width: 60px;


    }
  .openMenu {

  }

.pushOut{transition: transform 1s ease;}
.pushOut-true{transform: translate(0,-150vh)}
.pushOut-false{transform: translate(0,0)}
table {
    border: 0!important;
    margin-right: 0!important;
    width: 100%!important;
    min-width:100% !important;
    margin:0px !important
}
tr:nth-child(odd) {
    background: hsla(0,0%,100%,.009);
}

tr {
    border-bottom: 1px solid #555;
    padding: 10px 5px;
}
td {text-align: left;font-size:80%; width:50% !important;}

.swiper-pagination{
      transform: translate3d(0, -20px, 0)!important;
}
.swiper-pagination-bullet{
  background:#fff !important;
}

@media (min-width: 601px) {
.nav-false{transform: translate(0,0)}

.logoHome{
  margin-left: 10%;
  transform:scale(.6);
}

}
@media (max-width: 728px) {
.nav-false{transform: translate(0,0)}
.nav-false{height:1px;opacity:0;}
.nav {z-index: 10;

background-image: linear-gradient(to right, rgba(219,12,105,0), rgba(107,39,217,0));
transition: opacity 1s ease, height 1s ease, background-image 1s ease;
  }
  .nav-true{
    opacity:1;
    flex: 0 1 auto;
    top: 0px;
padding-top: 100px;
min-height: 100%;
background-image: linear-gradient(to right bottom, rgba(219,12,105,0.7), rgba(107,39,217,1));  }
nav a li {width:100%;text-align:center;padding-top:10px;}
nav a li span{font-size:160%;letter-spacing:3px;font-weight:400;text-transform:uppercase;}
}
