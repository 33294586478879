._image-container {
  background-color: #222;
  overflow: hidden;
  position: relative;
  margin: 0px auto;
  position:relative;
  z-index:0;
}
._image {
  /*position: absolute;*/
  width: 100%;
  height: 100%;
  left:0px;
  top:0px;
  opacity: 0;
  transition: opacity 1s ease-out;
}

  ._image._thumb {
    opacity: 1;
    transition: opacity 2s ease-out;
    position: absolute;
  }
    ._image._thumb._isLoaded {
      opacity: .3;
    }


  ._image._isLoaded {
    opacity: 0;
  }

  ._image-init{
    opacity:0;
  }

  ._image._isVisible {
    opacity: 1;
  }

  ._image-on{
    transition-delay: .3s !important;
    opacity:0 !important;
    transition: opacity .1s ease-out;
  }
