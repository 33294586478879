.ttl_wrapper{
    opacity:1;
  }
  .ttl_wrapper > span{
    opacity:0;
    transition: transform 1s eas;
    display:inline-block;
  }
  .ttl_wrapper > span span {
    opacity:0;
    transition: opacity 1s ease;
    transition-delay: .5s;
    transform: rotateZ(-20deg);
    text-shadow: 2px 2px 2px rgba(0,0,0,.4);
  }
  .done{
    white-space: break-spaces !important;
  margin-right:4px
  }
